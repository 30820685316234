@import '~antd/dist/antd.css';

:root {
	--cor-1: #090;
}

button:hover {
	cursor: pointer;
}

* {
	box-sizing: border-box;
	outline: none;
}

/*
.ant-modal-content {
	background: var(--cor-1);
}
*/


body {
	background: #EEE;
}


h1 {
	font-size: 22px;
}



form .acoes {
	text-align: right;	
}
form .acoes button {
	margin-left: 7px;
}




.table-field-edit {
	display: flex;
	align-items: center;
}
.table-field-edit button {
	margin: 0;
	margin-left: 7px;
	background: none;
	border: none;
	padding: 0;
	height: 18px;
}
.table-field-edit button svg {
	font-size: 16px;
	padding: 0;
	margin: 0;	
	color: #777;
}