.auth-admin {	
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}


.auth-admin .box-login {
	display: flex;
	flex-direction: column;	
	width: 100%;
	max-width: 380px;
	background: #FFF;
	box-shadow: 0 0 3px #bbb;
	padding: 15px;
}

.auth-admin .box-login h1 {
	margin-bottom: 25px;
}