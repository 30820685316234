.campo-config {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
}


.campo-config p {
	margin: 0;
	width: 320px;
	text-align: right;
	margin-right: 10px;
	font-weight: 700;	
}